:root {
  --main-bg-color: hsl(220, 18%, 13%);
  --main-text-color: #ffffff;
  --main-bg-light-color: #4e5155;
  --transition-time: 0.5s;
  --font-style: Quicksand;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-style);
}

body {
  font-family: var(--font-style);
  background: var(--main-bg-color);
  color: var(--main-text-color);
}

.container {
  margin: 0;
  padding: 0;
}

header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 95vw;
}

header .logo {
  width: 22vw;
}

header .logo img {
  width: 100%;
  padding-left: 30px;
}

header nav {
  display: flex;
  justify-content: space-between;
}

header nav a {
  width: 18vw;
  padding: 5px 18vw 5px 0px;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-style);
  font-size: 2vw;
  color: orange;
  border:1px solid whitesmoke;
  border-radius: 8px;

}

.hero {
  color: var(--main-text-color);
  font-size: 20px;
  padding: 4rem 10rem;
}

.hero h1 {
  color: #ff9933;
  font-size: 2.5em;
}

.hero h1 strong {
  color: #ff9933;
}

.hero h1 span {
  color: white;
}

.hero p {
  width: 40vw;
  font-size: 1.3rem;
}

.hero button {
  background: rgba(255, 168, 39, 1);
  color: #000000;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 1.5rem;
  border: none;
  border-radius: 55px;
  margin-top: 2.5vh;
}

.about {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.why-us {
  width: 300px;
  border-radius: 8px;
  margin: 60px;
}

.extra {
  width: 40vw;
}

.extra h2 {
  font-size: 2.5rem;
  width: 40vw;
}

.extra p {
  font-size: 0.85rem;
  width: 70vw;
}
.extra p strong {
  color:rgba(255, 168, 39, 1) ;
}

.wedo {
  margin: 150px;
width: 100%;

}

section {
  width: 60vw;
  margin-bottom: 100px;
}

.why {
  padding-bottom: 50px;
  width: 150%;
}

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
  width: 100vw;
  font-size: 20px;
}

.feature h3 {
  width: 250px;
  height: 250px;
  background: rgba(217, 217, 217, 1);
  border-radius: 20px;
  padding: 50px 50px;
  margin: 10px 45px;
  color: #ff9933;
  font-size: 35px;
  text-align: left;
}

.feature p {
  width: 330px;
  font-size: 0.9rem;
}

.product-tag {
  font-weight: 800;
  font-family: var(--font-style);
  text-align: start;
  font-size: 50px;
}

.products {
  display: flex;
  justify-content: space-around;
}

.product {
  display: flex;
  width: 200px;
  height: 200px;
  overflow: hidden;
  text-align: center;
}

.product img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product p {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: var(--font-style);
}

.prodwrapper {
  transition: all var(--transition-time);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin-block: 4em;
}

.prodcard {
  transition: all var(--transition-time);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 18em;
  height: 14em;
  padding: 1em;
  background-color: var(--main-bg-color);
  border-radius: 1em;
}

.prodcard:hover {
  transition: all 0.5s;
  height: 17em;
}

.prodimage {
  transition: all var(--transition-time);
  position: absolute;
  inset: 0.5em 0.5em 0em 0.5em;
}

.prodcard:hover .prodimage {
  transition: all var(--transition-time);
  position: absolute;
  inset: -4em 2em 0em 2em;
}

.prodimg {
  transition: all var(--transition-time);
  max-width: 100%;
  object-fit: cover;
  border-radius: 0.8em;
}

.prodcard:hover .prodimg {
  transition: all var(--transition-time);
  border: 0.5em solid var(--main-bg-color);
  border-radius: 1.2em;
}

.prodp {
  transition: all var(--transition-time);
  position: absolute;
  top: 3em;
  opacity: 0;
}

.prodcard:hover .prodp {
  transition: all var(--transition-time);
  transition-delay: 0.5s;
  position: absolute;
  top: 6em;
  opacity: 1;
}

.prodbutton {
  position: absolute;
  bottom: 0.4em;
  left: 50%;
  translate: -50% 0;
  padding: 0.25em 1em;
  background: #ffa565;
  outline: 0em solid var(--main-bg-color);
  border-radius: 4em;
  transition: all var(--transition-time);
}

.prodcard:hover .prodbutton {
  transition: all var(--transition-time);
  bottom: -1.3em;
  cursor: pointer;
  padding: 0.5em 2em;
  outline: 0.5em solid var(--main-bg-color);
}

.faq {
  width: 100vw;
  margin: 0 auto;
  padding: 20px;
}

.faq h2 {
  padding: 30px 40px;
  font-weight: 700;
  font-family: var(--font-style);
}

.faq-item {
  margin-bottom: 15px;
  padding: 0px 40px;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.faq-item h3 {
  margin-bottom: 5px;
}

.faq-item p {
  padding: 0 10px;
  max-height: 0;
  transition: max-height 0.5s ease;
  width: 75vw;
}

.faq-item:hover p {
  max-height: 100px;
}

p {
  margin: 0;
  padding: 0;
}

footer {
  text-align: center;
  padding: 20px 0;
}

footer button {
  background: rgba(255, 168, 39, 1);
  color: #000000;
  padding: 18px 31px;
  cursor: pointer;
  font-size: 30px;
  border: none;
  border-radius: 55px;
}

@media (max-width: 768px) {
  .hero {
    padding: 4rem 6rem;
  }

  .hero h1 {
    width: 54vw;
    font-size: 30px;
  }

  .hero p {
    width: 200%;
    font-size: 0.87rem;
  }

  .feature p {
    width: 380px;
  }

  .features,
  .products {
    flex-direction: column;
  }

  .feature,
  .product {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .extra h2 {
    font-size: 1.7rem;
  }

  .extra p {
    font-size: 0.7rem;
  }

  .about img {
    width: 40vw;
  }
}

.product-tag {
  text-align: center;
  margin-top: 40px;
}

.extra span {
  font-weight: bold;
}

.extra h3 {
  color: rgba(24, 30, 81, 1);
}

@media (max-width: 550px) {
  .hero {
    padding: 4rem 4rem;
  }

  header .logo {
    width: 100px;
  }

  header nav a {
    font-size: 15px;
    padding: 5px;
  }

  .hero h1 {
    font-size: 30px;
  }

  .about img {
    width: 45vw;
  }

  .hero button {
    font-size: 13px;
  }

  .about {
    flex-direction: column;
    align-items:flex-start;
    padding: 0 17vw;
    gap: 20px;
  }

  .extra h2 {
    font-size: 1.7rem;
    width: 60vw;
  }

  .product-tag {
    font-size: 2.5rem;
  }

  .wedo {
    margin: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .prodwrapper {
    display: flex;
    flex-direction: column; 
    gap: 20px;
    width: 100%;
    align-items: center;
  }

  .prodcard {
    width: 90%; 
  }

  .prodbutton {
    text-align: center;
    margin-top: 10px;
  }
}


/* card */
.cardfeature {
  width: 300px;
  height: 300px;
  float: left;
  perspective: 500px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.cardfeature:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.cardfeature:active .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.back {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  font-family: var(--font-style);
  font-size: 18px;
  border-radius: 50px;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-color);
  color: white;
  transform: rotateY(180deg);
}

.front {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: var(--font-style);
  text-align: center;
  font-size: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  background-size: cover;
}

.front-b {
  background-image: url("./assets/Bulk.png");
}

.front-e {
  background-image: url("./assets/Quality.png");
}

.front-f {
  background-image: url("./assets/Fast.png");
}
@media (min-width: 769px) and (max-width: 1440px) {
  .hero {
    padding: 5rem 12rem;
  }

header nav a {
  padding-left: 20px;
}
.why-us{
  margin-left: 25vw;
  width: 100%;
}
  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.5rem;
  }

  .feature h3 {
    font-size: 2.5rem;
  }

  .feature p {
    font-size: 1.1rem;
  }

  .extra h2 {
    font-size: 3rem;
    padding-left: 64px;
  }

  .extra p {
    font-size: 1.5rem;
        padding-left: 61px;
  }

  .product-tag {
    font-size: 3rem;
  }
}

/* Media query for desktop screens (above 1440px) */
@media (min-width: 1441px) {
  .hero {
    padding: 6rem 15rem;
  }

  .hero h1 {
    font-size: 4rem;
  }

  .hero p {
    font-size: 2rem;
  }

  .feature h3 {
    font-size: 3rem;
  }

  .feature p {
    font-size: 1.2rem;
  }

  .extra h2 {
    font-size: 2.5rem;
  }

  .extra p {
    font-size: 1.2rem;
  }

  .product-tag {
    font-size: 4rem;
  }
}