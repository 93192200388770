@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

:root {
  --white: #fff;
  --smoke-white: #000000;
  --blue: #4169e1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px 0px;
}

.product {
  width: 290px;
  height: 390px;
  position: relative;
}

.product img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: opacity 500ms, transform 500ms;
}

.product:hover img {
  opacity: 0;
  transform: scale(4);
}

.product p {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  transition: transform 700ms;
  transform: translateX(500px);
}

.product:hover p {
  transform: translateX(0);
}

.prodcontainer {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.radiocontainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector {
  position: relative;
  width: 60%;
  background-color: var(--smoke-white);
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 9999px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}
.selector-item {
  position: relative;
  flex-basis: calc(70% / 3);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector-item_radio {
  appearance: none;
  display: none;
}
.selector-item_label {
  position: relative;
  height: 80%;
  width: 100%;
  text-align: center;
  border-radius: 9999px;
  line-height: 400%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  transition-duration: 0.5s;
  transition-property: transform, box-shadow;
  transform: none;
}
.selector-item_radio:checked + .selector-item_label {
  background-color: var(--blue);
  color: var(--white);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
}
.propcard {
  width: 250px;
  background-color: rgb(144, 146, 147);
}
.prodcontainer {
  font-size: 0.8rem;
  gap: 0;
  padding: 0px 0px;
  width: 250px;
  background-color: hsl(180, 6%, 3%);
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.propcard img {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

@media (max-width: 480px) {
  .selector {
    width: 76%;
  }
  .card-text {
    display: flex;
    flex-wrap: wrap;
    padding-left: 8px;
  }
  .products {
    flex-direction: row;
    gap: 5px;
  }

  .prodcontainer {
    width: 49vw;
    font-size: 0.8rem;
    gap: 0;

    padding: 0px 0px;
  }
  .propcard {
    width: 49vw;
    background-color: rgb(144, 146, 147);
    height: fit-content;
  }
  .propcard img {
    width: 49vw;
    height: 250px;
    object-fit: cover;
  }
}
.mt-4 {
  color: rgb(251, 172, 26);
  padding-left: 10px;
  /* position: relative; */
}
